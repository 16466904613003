import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import './Third.scss';

import DiagnosticIcon from './assets/diagnostic.inline.svg';
import RepairIcon from './assets/repair.inline.svg';
import DeliveryIcon from './assets/delivery.inline.svg';
import GuaranteeIcon from './assets/guarantee.inline.svg';

const Card = ({ id, Image, Icon, title, text, list }) => {
  return (
    <div className="card">
      <div className="card__content">
        <div className="card__content-top">
          <div className="card__number">{'0' + id}</div>
          <div className="card__title h4">{title}</div>
          <div className="card__text">{text}</div>
        </div>
        <div className="card__content-bottom">
          <ul className="card__list">
            {list.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="card__image">
        <div className="card__image-image">{Image}</div>
        <div className="card__image-icon">{<Icon />}</div>
      </div>
    </div>
  );
};

const Third = () => {
  const data = useStaticQuery(graphql`
    query AboutCardsImages {
      allFile(filter: { relativeDirectory: { eq: "process" } }) {
        edges {
          node {
            name
            extension
            dir
            modifiedTime
            childImageSharp {
              gatsbyImageData(width: 314, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  `);

  const imagesList = data.allFile.edges;

  const cards = [
    {
      id: 1,
      label: 'diagnostic',
      title: 'Диагностика оборудования',
      text: 'Оценка состояния, поиск всех неисправностей.',
      list: ['Конкретные сроки', 'Точная стоимость', 'Квитанцию о приеме'],
      icon: DiagnosticIcon,
    },
    {
      id: 2,
      label: 'repair',
      title: 'Ремонт и тестирование',
      text: 'Устранение поломок разного уровня сложности. Тестирование под дополнительной нагрузкой.',
      list: ['Уведомление об окончании ремонта'],
      icon: RepairIcon,
    },
    {
      id: 3,
      label: 'delivery',
      title: 'Выдача исправного оборудования',
      text: 'Выдаем в сервисе. По предъявлении квитанции, выданную при сдаче в сервис. Оплачиваете и забираете рабочую технику.',
      list: ['В удобное для вас время', 'По всей России и СНГ'],
      icon: DeliveryIcon,
    },
    {
      id: 4,
      label: 'guarantee',
      title: 'Гарантия на ремонт',
      text: 'Следующие 14 дней мы молимся богу чтобы у вас ничего не сломалось, а если сломается — поможем починить.',
      list: ['Гарантийный лист'],
      icon: GuaranteeIcon,
    },
  ];

  return (
    <section className="third">
      <div className="container">
        <div className="section">
          <div className="row">
            <div className="col-lg-3">
              <div className="section-number">
                <span className="section-number__number">[03]</span>
                <span className="section-number__title">Процесс работы</span>
              </div>
            </div>
            <div className="col-lg-9">
              <h2 className="section__title">Порядок проведения ремонта</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-9 offset-lg-3">
              <div className="third__cards">
                {cards.map(({ id, label, title, text, list, icon }) => {
                  const image = getImage(
                    imagesList.find((image) => label === image.node.name).node,
                  );

                  return (
                    <Card
                      key={id}
                      id={id}
                      Image={
                        <GatsbyImage
                          image={image}
                          style={{ height: '100%', width: '100%' }}
                          imgStyle={{ objectFit: 'cover' }}
                          alt=""
                        />
                      }
                      Icon={icon}
                      title={title}
                      text={text}
                      list={list}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Third;
