import * as React from 'react';

import '@fontsource/manrope/600.css';
import '@fontsource/manrope/700.css';
import '@fontsource/ibm-plex-sans/400.css';
import '@fontsource/ibm-plex-sans/500.css';

import '../styles/style.scss';

import Layout from '../components/layout/Layout';
import Seo from '../components/seo/Seo';
import Hero from '../components/hero/Hero';
import First from '../components/home/first/First';
import Second from '../components/home/second/Second';
import Third from '../components/home/third/Third';
import Fourth from '../components/home/fourth/Fourth';
// import Fifth from '../components/home/fifth/Fifth';
import Sixth from '../components/home/sixth/Sixth';
import Seventh from '../components/home/seventh/Seventh';

const IndexPage = () => {
  return (
    <Layout homepage>
      <Seo
        title="Чиним Возим"
        description="Быстро и качественно ремонтируем асик-майнеры и блоки питания с гарантией на работу точно в срок"
      />

      <Hero />
      <First />
      <Second />
      <Third />
      <Fourth />
      {/* <Fifth /> */}
      <Sixth />
      <Seventh />
    </Layout>
  );
};

export default IndexPage;
