import React from 'react';

import './Avito.scss';

import AvitoLogo from './assets/avito.inline.svg';
import Logo from './assets/logo.inline.svg';

import Stars from '../stars/Stars';

const Avito = () => {
  return (
    <div className="avito">
      <div className="avito__top">
        <AvitoLogo />
      </div>
      <div className="avito__content">
        <div className="avito__rating">
          <div className="avito__rating-icon">
            <Logo />
          </div>
          <div className="avito__rating-content">
            <div className="avito__rating-title">Чиним Возим</div>
            <div className="avito__rating-stars">
              <div className="avito__rating-value">5.0</div>
              <Stars />
            </div>
          </div>
        </div>
        <a
          href="https://www.avito.ru/user/9b0486a9a37362d27dff52da5f9b0c85/profile?id=2335629347&src=item&page_from=from_item_card&iid=2335629347#open-reviews-list"
          className="btn btn--blue btn--small btn--full"
          target="_blank"
          rel="noreferrer"
        >
          смотреть отзывы{' '}
        </a>
      </div>
    </div>
  );
};

export default Avito;
