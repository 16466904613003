import React from 'react';

import './Second.scss';

import CheckIcon from './assets/check.inline.svg';

const Second = () => {
  return (
    <section className="second">
      <div className="container">
        <div className="section">
          <div className="row">
            <div className="col-lg-3">
              <div className="section-number">
                <span className="section-number__number">[02]</span>
                <span className="section-number__title">Правила</span>
              </div>
            </div>
            <div className="col-lg-9">
              <h2 className="section__title">
                Мы не нарушаем <br /> важные для вас правила
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-9 offset-lg-3">
              <div className="second__features">
                <div className="second__feature">
                  <div className="second__feature-icon">
                    <CheckIcon />
                  </div>
                  <div className="second__feature-title h4">
                    Не меняем платы клиента на чужие без его согласия
                  </div>
                  <div className="second__feature-text">
                    Многие сервисы меняют хорошую плату клиента, на более
                    ломанную, чтобы забрать себе вашу плату на перепродажу
                  </div>
                </div>

                <div className="second__feature">
                  <div className="second__feature-icon">
                    <CheckIcon />
                  </div>
                  <div className="second__feature-title h4">
                    Не ломаем платы для собственной выгоды
                  </div>
                  <div className="second__feature-text">
                    Некоторые сервисы ломают платы, или говорят что их
                    невозможно починить, чтобы выкупить их для последующей
                    перепродажи
                  </div>
                </div>

                <div className="second__feature">
                  <div className="second__feature-icon">
                    <CheckIcon />
                  </div>
                  <div className="second__feature-title h4">
                    Не затягиваем сроки для заработка на ваших устройствах
                  </div>
                  <div className="second__feature-text">
                    Некоторые сервисы ломают платы, или говорят что их
                    невозможно починить, чтобы выкупить их для последующей
                    перепродажи
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Second;
