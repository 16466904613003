import React, { useState, useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

import { ModalContext } from '../../../contex/modal-context';

import 'swiper/css';
import './First.scss';

import Ebang from '../../../assets/partners/ebang.svg';
import Whatsminer from '../../../assets/partners/whatsminer.svg';
import BitmainIcon from '../../../assets/partners/bitmain.svg';
import CanaanIcon from '../../../assets/partners/canaan.svg';
import InnosiliconIcon from '../../../assets/partners/innosilicon.svg';
import MicroBT from '../../../assets/partners/microbt.svg';
import Antminer from '../../../assets/partners/antminer.svg';

import PlusIcon from './assets/plus.inline.svg';
import MinusIcon from './assets/minus.inline.svg';

const logos = [
  {
    name: 'ebang',
    image: Ebang,
    width: '136',
    height: '28',
  },
  {
    name: 'whatsminer',
    image: Whatsminer,
    width: '211',
    height: '38',
  },
  {
    name: 'bitmain',
    image: BitmainIcon,
    width: '207',
    height: '26',
  },
  {
    name: 'canaan',
    image: CanaanIcon,
    width: '142',
    height: '38',
  },
  {
    name: 'innosilicon',
    image: InnosiliconIcon,
    width: '250',
    height: '26',
  },
  {
    name: 'microbt',
    image: MicroBT,
    width: '146',
    height: '38',
  },
  {
    name: 'antminer',
    image: Antminer,
    width: '245',
    height: '32',
  },
];

const models = [
  ['Antminer S19J PRO', 'Whatsminer M32', 'Avalon 1264', 'Innosilicon  T2T Z'],
  ['Antminer S19J', 'Whatsminer M31S', 'Avalon 1066', 'Innosilicon  T2T+'],
  ['Antminer S19', 'Whatsminer M31', 'Avalon 1045', 'Innosilicon  T3L'],
  ['Antminer T19', 'Whatsminer M30S', 'Avalon 841', 'Innosilicon A10'],
  ['Antminer S17+', 'Whatsminer M30', 'Avalon 855', 'Innosilicon A11'],
  ['Antminer S17', 'Whatsminer M21S', 'Avalon 852'],
  ['Antminer T17+', 'Whatsminer M20S'],
  ['Antminer T17'],
  ['Antminer T15'],
  ['Antminer S15'],
  ['Antminer S9'],
  ['Antminer L7'],
  ['Antminer L3++'],
  ['Antminer L3+'],
  ['Antminer L3'],
];

const First = () => {
  const { openModal } = useContext(ModalContext);
  const [open, setOpen] = useState(false);

  const openForm = () => {
    openModal({
      variant: 'callback',
    });
  };

  return (
    <section className="first">
      <div className="container">
        <div className="section">
          <div className="row">
            <div className="col-lg-3">
              <div className="section-number">
                <span className="section-number__number">[01]</span>
                <span className="section-number__title">
                  Что мы ремонтируем
                </span>
              </div>
            </div>
            <div className="col-lg-9">
              <h2 className="section__title">
                Мы можем отремонтировать что угодно, включая блоки питания, но
                если хочется конкретики, то вот она
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 side">
              <div className="h4 side__title">
                Проконсультироваться у специалиста
              </div>
              <div className="side__text">
                Позвоните инженеру, который может ответить на любые вопросы по
                ремонту и настройке вашего оборудования.
              </div>
              <a href="tel:+74951330030" className="side__phone h4">
                +7 (495) 133-00-30
              </a>
              <button className="btn" onClick={openForm}>
                Получить консультацию
              </button>
            </div>
            <div className="col-lg-9">
              <div className="list-group">
                <div className="list-group__item h4">Ремонт хэш-плат</div>
                <div className="list-group__item h4">
                  Ремонт контрольных плат
                </div>
                <div className="list-group__item h4">Ремонт блоков питания</div>
                <div className="list-group__item h4">Прошивка майнеров</div>
                <div className="list-group__item h4">Лечение от вирусов</div>
                <div className="list-group__item h4">
                  Ремонт разъемов питания
                </div>
                <div className="list-group__item h4">Замена шлейфов</div>
                <div className="list-group__item h4">Замена вентиляторов</div>
                <div className="list-group__item h4">Ремонт/замена кабелей</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="first__slider">
        <Swiper
          slidesPerView={'auto'}
          loop={true}
          centeredSlides={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
        >
          {logos.map(({ name, image, width, height }) => (
            <SwiperSlide key={name}>
              <img src={image} width={width} height={height} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="first__table">
        <div className="container">
          <div className="table-wrapper">
            <table className="table">
              <thead className="table__head">
                <tr>
                  <th>
                    <span className="table__head-link">Bitmain</span>
                  </th>
                  <th>
                    <span className="table__head-link">MicroBT</span>
                  </th>
                  <th>
                    <span className="table__head-link">Canaan</span>
                  </th>
                  <th>
                    <span className="table__head-link">PushMiner</span>
                  </th>
                </tr>
              </thead>
              <tbody className="table__content">
                {models.slice(0, 5).map((row, idx) => (
                  <tr key={idx}>
                    {row.map((col) => (
                      <td key={col}>
                        <span className="table__link">{col}</span>
                      </td>
                    ))}
                  </tr>
                ))}
                {open &&
                  models.slice(5, models.length - 1).map((row, idx) => (
                    <tr key={idx}>
                      {row.map((col) => (
                        <td key={col}>
                          <span key={col} className="table__link">
                            {col}
                          </span>
                        </td>
                      ))}
                    </tr>
                  ))}
                <tr>
                  <td colSpan={4}>
                    <button
                      type="button"
                      className="table__link  table__link--toggle"
                      onClick={() => setOpen(!open)}
                    >
                      {open ? 'Скрыть модели' : 'Показать еще'}
                      {open ? (
                        <MinusIcon style={{ marginLeft: 12 }} />
                      ) : (
                        <PlusIcon style={{ marginLeft: 12 }} />
                      )}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default First;
