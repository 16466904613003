import React, { useContext } from 'react';

import './Hero.scss';

import { ModalContext } from '../../contex/modal-context';

import BlockLink from '../block-link/BlockLink';
import CalendarIcon from './assets/calendar.inline.svg';
import RepairIcon from './assets/repair.inline.svg';
import GuaranteeIcon from './assets/guarantee.inline.svg';

const Hero = () => {
  const { openModal } = useContext(ModalContext);

  const openForm = () => {
    openModal({
      variant: 'callback',
    });
  };

  return (
    <>
      <section className="hero">
        <div className="hero__wrapper">
          <div className="hero__bg" />
          <div className="container">
            <div className="hero__content">
              <h1 className="hero__title">
                Чиним любые поломки на любом оборудовании для майнинга
              </h1>
              <div className="hero__text">
                Быстро и качественно ремонтируем асик-майнеры
                <br />и блоки питания с гарантией на работу точно в срок
              </div>
              <button className="btn" onClick={openForm}>
                Получить консультацию
              </button>
            </div>
            <div className="hero__links">
              <BlockLink
                to="/catalog"
                title="Магазин"
                text="Наш каталог майнинг оборудования от ведущих производителей в мире"
                light
              />
              <BlockLink
                to="/hotel"
                title="Майнинг-отель"
                text="Наш отель 80 км от МКАД с электричеством от 4,9₽ за 1кВт"
                light
              />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="hero__features">
            <div className="hero__feature">
              <div className="hero__feature-icon">
                <CalendarIcon />
              </div>
              <div className="hero__feature-content">
                <div className="hero__feature-title">Конкретные сроки</div>
                <div className="hero__feature-text">
                  В течении 1 дня с приемки мы скажем дату, когда вы сможете
                  забрать рабочее устройство.
                </div>
              </div>
            </div>
            <div className="hero__feature">
              <div className="hero__feature-icon">
                <RepairIcon />
              </div>
              <div className="hero__feature-content">
                <div className="hero__feature-title">
                  Ремонт любой сложности
                </div>
                <div className="hero__feature-text">
                  Ремонтируем Antminer, Innosilicon, Whatsminer и другие
                  популярные серии
                </div>
              </div>
            </div>
            <div className="hero__feature">
              <div className="hero__feature-icon">
                <GuaranteeIcon />
              </div>
              <div className="hero__feature-content">
                <div className="hero__feature-title">С гарантией на работу</div>
                <div className="hero__feature-text">
                  В зависимости от типа работ мы предоставляем гарантию до 1
                  года
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
