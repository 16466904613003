import React from 'react';

import './Sixth.scss';

import Faq from '../../faq/Faq';

const Sixth = () => {
  const list = [
    {
      title: 'Как быстро вы чините?',
      text: 'Максимальный срок ремонта небольшого объема — 2 недели. В зависимости от ваших пожеланий и нашей загруженности мы можем это может быть быстрее.',
    },
    {
      title: 'Какая стоимость ремонта?',
      text: 'Стоимость ремонта зависит от модели. Точную мы можем сказать после диагностики. Примерные цены вы всегда можете узнать у менеджера.',
    },
    {
      title: 'Сколько вы даете гарантию на ремонт?',
      text: 'Гарантия от нашего сервиса на горячий ремонт от 2 недель. В любом случае, если что-то пошло не так вы можете позвонить нам и мы поможем.',
    },
    {
      title: 'Какая гарантия на оборудование?',
      text: 'От 2 недель и более от нашего сервиса и год от производителя при заказе оборудования из Китая.',
    },
    {
      title: 'Как происходит проверка оборудования?',
      text: 'После ремонта оборудование проходит проверку до 3х часов, после которой мы оповещаем вас о готовности оборудования для получения.',
    },
    {
      title: 'Сроки доставки оборудования?',
      text: 'В зависимости от набора оборудования и наличия на складе в Москве, или в Китае мы доставляем от 1 дня до 10 рабочих дней.Как привезти оборудование к вамВы можете привезти оборудование самостоятельно к нам в офис на Ленинский проспект 121к1, а также отправить ваше оборудование CDEKом, или другой транспортной компанией. Перед отправкой позвоните нам и мы проконсультируем как лучше упаковать устройства.',
    },
  ];

  return (
    <section className="sixth">
      <div className="container">
        <div className="section">
          <div className="row">
            <div className="col-lg-3">
              <div className="section-number">
                <span className="section-number__number">[06]</span>
                <span className="section-number__title">FAQ</span>
              </div>
            </div>

            <div className="col-lg-9">
              <div className="section__title h2">Часто задаваемые вопросы</div>

              <Faq list={list} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sixth;
