import React from 'react';

import './Fourth.scss';

import Avito from '../../avito/Avito';
import Reviews from '../../reviews/Reviews';

const Fourth = () => {
  return (
    <section className="fourth">
      <div className="container">
        <div className="section">
          <div className="row">
            <div className="col-lg-3">
              <div className="section-number">
                <span className="section-number__number">[04]</span>
                <span className="section-number__title">Отзывы</span>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-9">
                  <h2 className="section__title">
                    Мнение наших клиентов по работе и ремонту оборудования
                  </h2>
                </div>

                <div className="col-lg-3 fourth__avito">
                  <Avito />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fourth__reviews">
        <Reviews />
      </div>
    </section>
  );
};

export default Fourth;
