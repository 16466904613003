import React from 'react';

import './Reviews.scss';
import Stars from '../stars/Stars';

const reviews = [
  {
    name: 'Нодар Бегиашвили',
    type: 'Ремонт хэш-плат',
    text: 'Результатом ремонта доволен. Рекомендую!',
  },
  {
    name: 'Сергей',
    type: 'Ремонт хэш-плат',
    text: 'Всё супер!',
  },
  {
    name: 'Михаил',
    type: 'Ремонт блоков питания',
    text: 'Отдал больше 20 блоков в ремонт. Большую часть отдали почти сразу, пересобрав из рабочих деталей. Остальное починили и отдавали по готовности. Супер!',
  },
  {
    name: 'Вова Мисюрин',
    type: 'Ремонт хэш-плат',
    text: 'Большое спасибо сделали айсики качественно,была плата на гарантии исправили качественно и бесплатно',
  },
  {
    name: 'Станислав',
    type: 'Ремонт блоков питания',
    text: 'Прекрасная работа, недорого, качественно в оговоренные сроки.',
  },
  {
    name: 'Иван',
    type: 'Ремонт хэш-плат',
    text: 'Ремонт произвели качественно и быстро.  Отличные цены на рынке. В процессе ремонта поддерживают связь с клиентом. По готовности отзваниваются.  Однозначно рекомендую к сотрудничеству!',
  },
  {
    name: 'Вова Перегудов',
    type: 'Ремонт хэш-плат',
    text: 'Отличная работа, быстро и прозрачно!  Объяснили все, что было непонятно. Сделали s9, от которых другие отказывались и в кратчайшие сроки! Спасибо огромное ребятам.! Теперь только к ним)',
  },
  {
    name: 'Валентина',
    type: 'Ремонт хэш-плат',
    text: 'Муж уехал в командировку, оставил на меня все.. благо попалось обьявление.  Ехала очень боялась, но как оказалось очень зря.  Приятные молодые ребята. Удобное расположение. Быстро. И цена приемлемая.  Всем рекомендую!',
  },
  {
    name: 'Владимир',
    type: 'Ремонт хэш-плат',
    text: 'Отличные ребята! Отличная работа! Ремонтировал у них платы от S9. Рекомендую к сотрудничеству',
  },
];

const ReviewItem = ({ text, name, type }) => {
  return (
    <div key={text} className="reviews__item">
      <div className="reviews__top">
        <div className="reviews__author">
          <div className="reviews__author-image">{name[0]}</div>
          <div className="reviews__author-content">
            <div className="reviews__author-name">{name}</div>
            <div className="reviews__author-subtitle">{type}</div>
          </div>
        </div>

        <div className="reviews__stars">
          <Stars rating={5} />
        </div>
      </div>

      <div className="reviews__text">{text}</div>
    </div>
  );
};

const Reviews = () => {
  const groupedReviews = [];

  for (let i = 0; i < reviews.length; i += 2) {
    groupedReviews.push([reviews[i], reviews[i + 1]]);
  }

  return (
    <div className="reviews">
      <div className="reviews__scroller">
        {groupedReviews.map((group, idx) => {
          return (
            <div key={idx} className="reviews__col">
              {group
                .filter((item) => item !== undefined)
                .map(({ text, name, type }) => (
                  <ReviewItem key={text} text={text} name={name} type={type} />
                ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Reviews;
