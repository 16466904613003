import React from 'react';

import './Stars.scss';

import StarIcon from './assets/star.inline.svg';

const Stars = ({ rating = 5 }) => {
  return (
    <div className="stars">
      {[...Array(rating)].map((star, idx) => {
        return <StarIcon key={idx} />;
      })}
    </div>
  );
};

export default Stars;
